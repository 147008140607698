import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../../AppContext';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {Heading, Button, Text, Row} from '../../Widgets';
import {Link} from 'gatsby';
import Item from './BlogItem';
import {Row as AntRow, Col} from 'antd';
const appConfig = require('../../data.json');

export default function BlogList(props) {
  const app = useContext(Context);
  const {label} = props.pageContext;
  const {services, blogLabels: labels} = app.state;
  const [records, setRecords] = useState([]);

  useEffect(() => {
    (async () => {
      if (services.length > 0) {
        app.actions.setLoading(true);
        try {
          const filters = [
            {
              label: {$not: {$regex: 'FAQ'}},
            },
            {
              label: {$not: {$regex: 'draft'}},
            },
          ];

          if (label) {
            filters.push({
              label: {$regex: label},
            });
          }
          let resp = await app.actions.getBlogs({
            query: {
              $and: filters,
            },
          });

          setRecords(resp);
        } catch (ex) {
          console.warn(ex);
        }
        app.actions.setLoading(false);
      }
    })();
  }, [app.actions, label, services]);

  return (
    <ContentWrapper>
      {!label && (
        <>
          <div style={{textAlign: 'center', marginBottom: 20}}>
            <Heading
              style={{
                padding: 10,
                borderBottom: '1px solid #eee',
                display: 'inline',
              }}>
              最新文章
            </Heading>
          </div>

          <AntRow gutter={[20, 20]} style={{alignSelf: 'stretch'}}>
            {records
              .sort((a1, a2) => (a1.created < a2.created ? 1 : -1))
              .slice(0, 3)
              .map((record) => (
                <Col xs={24} md={8} key={record.id}>
                  <Item
                    key={record.id}
                    record={record}
                    extraStyle={{}}
                    type="portrait"
                  />
                </Col>
              ))}
          </AntRow>
        </>
      )}
      <div style={{textAlign: 'center', marginBottom: 20}}>
        <Heading
          style={{
            padding: 10,
            borderBottom: '1px solid #eee',
            display: 'inline',
          }}>
          {label || '分類文章'}
        </Heading>
      </div>
      <Row style={{justifyContent: 'center', marginBottom: 20}}>
        {['全部文章', ...labels].map((_label, idx) => {
          const type =
            label && label === _label
              ? 'primary'
              : !label && idx === 0
              ? 'primary'
              : 'default';

          return (
            <Button
              key={_label}
              style={{
                margin: 10,
                padding: '4px 15px',
                height: 32,
                lineHeight: 1.5715,
              }}
              type={type}>
              <Link to={idx === 0 ? `/articles` : `/articles/${_label}`}>
                {_label}
              </Link>
            </Button>
          );
        })}
      </Row>

      <div style={{borderTop: '1px solid #e0e0e0', marginBottom: 40}}>
        {records.map((record) => (
          <Item
            key={record.id}
            record={record}
            extraStyle={{marginBottom: 10}}
            type="landscape"
          />
        ))}
      </div>
    </ContentWrapper>
  );
}
